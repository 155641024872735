export const commonIcons = {
  "lingotion_add": require('@/assets/icons/icon_add.svg'),
  "lingotion_download": require('@/assets/icons/icon_download.svg'),
  "lingotion_edit": require('@/assets/icons/icon_edit.svg'),
  "lingotion_ethics": require('@/assets/icons/icon_ethics.svg'),
  "lingotion_ethics_Balanced": require('@/assets/icons/icon_ethics_balanced.svg'),
  "lingotion_ethics_Case By Case": require('@/assets/icons/icon_ethics_case.svg'),
  "lingotion_ethics_Liberal": require('@/assets/icons/icon_ethics_liberal.svg'),
  "lingotion_ethics_Strict": require('@/assets/icons/icon_ethics_strict.svg'),
  "lingotion_expand":require('@/assets/icons/icon_expand.svg'),
  "lingotion_trash": require('@/assets/icons/icon_trash.svg'),
  "lingotion_add_full": require('@/assets/icons/icon_add_full.svg'),
  "lingotion_actors_all": require('@/assets/icons/icon_actors_all.svg'),
  "lingotion_cart": require('@/assets/icons/icon_cart.svg'),
  "lingotion_question": require('@/assets/icons/icon_question.svg'),
  "lingotion_email": require('@/assets/icons/icon_email.svg'),
  "lingotion_logo": require('@/assets/icons/icon_logo.svg'),
};
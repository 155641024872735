import restapi, { mobileApi } from "./restapi";
import tools from "./tools";
import axios from "axios";

export default class DatabaseService {
/*Constructor and helpers*/
  constructor (accessToken, store) {
    this.accessToken=null;
    this.accessTokenFunction=null;
    if (typeof accessToken=="string") {
      this.accessToken=accessToken;
    } else if (typeof accessToken=="function") {
      this.accessTokenFunction=accessToken;
    } else {
      console.error("Unknown type of accesstoken:"+typeof accessToken);
    }
    this.store = store; //Global variable vuex storage
  }

  async getAccessToken() {
    if (this.accessToken!=null) {
      return this.accessToken;
    } else if (this.accessTokenFunction!=null) {
      this.accessToken=await this.accessTokenFunction();
      return this.accessToken;
    } else {
      console.error("No access token");
    }
  }

  getDataForm() {
    const data = new FormData();
    if (this.store != undefined) {
      //We might create a stand alone database service outside the Vue component so lets check we have the global vue store variable before using the getters
      let selectedUserKey = this.store.getters.getSelectedUserKey();
      if (selectedUserKey != null) {
        data.append('selecteduserkey',selectedUserKey);
      }
    }
    return data;
  }

/*Generic Misc info*/
  async getLanguageCodes() {
    const data = this.getDataForm();
    return restapi.post("/getlanguagecodes", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getCountryCodes() {
    const data = this.getDataForm();
    return restapi.post("/getcountrycodes", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getSubRegionCodes() {
    const data = this.getDataForm();
    return restapi.post("/getsubregioncodes", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }



/*Service Helpers*/
  uploadToStorageUrl(url, uploadData, mimeType) {
    return axios.put(url, uploadData, {
      headers: {
        "Content-type": mimeType
      }
    });
  }



/*Recordings*/
  async getUploadRecordingStorageUrls(recordingSetKey, uploadRequest) {
    const data = this.getDataForm();
    data.append('recordingsetkey', recordingSetKey);
    data.append('uploadrequest', JSON.stringify(uploadRequest));
    return restapi.post("/getuploadrecordingstorageurls", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async registerUploadedRecording(uploadRequest, recordingTime, recordingSetKey, textKey, recordingEmotion, hasAudio, hasVideo, clapperboardTimeSeconds, prepareRecordingTimeSeconds) {
    const data = new FormData()
    data.append('uploadrequest', JSON.stringify(uploadRequest));
    data.append('recordingtime', recordingTime);
    data.append('recordingsetkey', recordingSetKey);
    data.append('textkey', textKey);
    data.append('recordingemotion', JSON.stringify(recordingEmotion));
    var browserInfo=tools.getBrowserInfo();
    data.append('recorderdeviceinfo', browserInfo);
    data.append('hasaudio', hasAudio);
    data.append('hasvideo', hasVideo);
    data.append('clapperboardtimeseconds', JSON.stringify(clapperboardTimeSeconds));
    data.append('preparerecordingtimeseconds', JSON.stringify(prepareRecordingTimeSeconds));
    return restapi.post("/registerUploadedRecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }
  async getRecordingSetsForUser(onlyActiveRecordings) {
    const data = this.getDataForm();
    data.append('onlyactiverecordings',onlyActiveRecordings);
    return restapi.post("/getrecordingsetsforuser", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setActiveRecordingForRecordingSet(recordingSetKey,activeRecording) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('activerecording',activeRecording);
    return restapi.post("/setactiverecordingforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setArchivedForDeletionForRecordingSet(recordingSetKey,archivedForDeletion) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('archivedfordeletion',archivedForDeletion);
    return restapi.post("/setarchivedfordeletionforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async createRecordingSet(textSetKey,userLanguageKey,recordingSetName,recordVideo,defaultAudioReflectionFreeRecording) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    data.append('userlanguagekey',userLanguageKey);
    data.append('recordingsetname',recordingSetName);
    data.append('recordvideo',recordVideo);
    data.append('defaultaudioreflectionfreerecording',defaultAudioReflectionFreeRecording);
    return restapi.post("/createrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getTextsForRecordingSet(recordingSetKey,offsetRows,fetchRows,sortColumn,sortOrder) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('offsetrows',offsetRows);
    data.append('fetchrows',fetchRows);
    data.append('sortcolumn',sortColumn);
    data.append('sortorder',sortOrder);
    return restapi.post("/gettextsforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getInfoForRecordingSet(recordingSetKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    return restapi.post("/getinfoforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getInfoForRecordingText(recordingSetKey,textKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('textkey',textKey);
    return restapi.post("/getinfoforrecordingtext", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async setDefaultEmotionSetForRecordingSet(recordingSetKey,defaultEmotionSetKey) {
    const data = this.getDataForm();
    data.append('defaultemotionsetkey',defaultEmotionSetKey);
    data.append('recordingsetkey',recordingSetKey);
    return restapi.post("/setdefaultemotionsetforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async setBaselineEmotionSetForRecordingSet(recordingSetKey,baselineEmotionSetKey) {
    const data = this.getDataForm();
    data.append('baselineemotionsetkey',baselineEmotionSetKey);
    data.append('recordingsetkey',recordingSetKey);
    return restapi.post("/setbaselineemotionsetforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getNextUnrecordedText(recordingSetKey,currentTextKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('currenttextkey',currentTextKey);
    return restapi.post("/getnextunrecordedtext", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getRecordingStatistics(videoRecordings, userLanguageKey=null) {
    const data = this.getDataForm();
    data.append('videorecordings',videoRecordings);
    if (userLanguageKey!=null) {
      data.append('userlanguagekey',userLanguageKey);
    }
    return restapi.post("/getrecordingstatistics", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getRecordingOrderAlgorithms() {
    const data = this.getDataForm();
    return restapi.post("/getrecordingorderalgorithms", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async setRecordingOrderAlgorithmForRecordingSet(recordingSetKey,recordingOrderAlgorithmID) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('recordingorderalgorithmid',recordingOrderAlgorithmID);
    return restapi.post("/setrecordingorderalgorithmforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }



  async getRecordingsForRecordingText(recordingSetKey,textKey) {
    const data = this.getDataForm();
    data.append('textkey',textKey);
    data.append('recordingsetkey',recordingSetKey);
    return restapi.post("/getrecordingsforrecordingtext", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getRecordingEmotionsForText(recordingSetKey,textKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('textkey',textKey);
    return restapi.post("/getrecordingemotionsfortext", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async deleteRecording(recordingKey) {
    const data = this.getDataForm();
    data.append('recordingkey',recordingKey);
    return restapi.post("/deleterecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async selectRecording(recordingKey,recordingSetKey,textKey,recordingEmotionKey) {
    const data = this.getDataForm();
    data.append('recordingkey',recordingKey);
    data.append('recordingsetkey',recordingSetKey);
    data.append('textkey',textKey);
    data.append('recordingemotionkey',recordingEmotionKey);
    return restapi.post("/selectrecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getSelectedRecordings(recordingSetKey,textKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('textkey',textKey);
    return restapi.post("/getselectedrecordings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getRecording(recordingKey) {
    const data = this.getDataForm();
    data.append('recordingkey',recordingKey);
    return restapi.post("/getrecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getMediaForRecordingData(recordingDataKey, createVideoPreview, useCachedData=true, audioDeviceNumberForVideoPreview=0) {
    const data = this.getDataForm();
    data.append('recordingdatakey',recordingDataKey);
    data.append('createvideopreview',createVideoPreview);
    data.append('usecacheddata',useCachedData);
    data.append('audiodevicenumberforvideopreview',audioDeviceNumberForVideoPreview);
    return restapi.post("/getmediaforrecordingdata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getVideoFramesForRecordingData(recordingDataKey, onlyClapperTime) {
    const data = this.getDataForm();
    data.append('recordingdatakey',recordingDataKey);
    data.append('onlyclappertime',onlyClapperTime);
    return restapi.post("/getvideoframesforrecordingdata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }


  async getEmotionSets() {
    const data = this.getDataForm();
    return restapi.post("/getemotionsets", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setNameForRecordingSet(recordingSetKey,name) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('name',name);
    return restapi.post("/setnameforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setDefaultAudioReflectionFreeRecording(recordingSetKey,defaultAudioReflectionFreeRecording) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('defaultaudioreflectionfreerecording',defaultAudioReflectionFreeRecording);
    return restapi.post("/setdefaultaudioreflectionfreerecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setUserLanguageKeyForRecordingSet(recordingSetKey,userLanguageKey) {
    const data = this.getDataForm();
    data.append('recordingsetkey',recordingSetKey);
    data.append('userlanguagekey',userLanguageKey);
    return restapi.post("/setuserlanguagekeyforrecordingset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async uploadMicCalibrationRecording(recordingBlob, referenceCalibration,micInfo) {
    const data = new FormData()
    data.append('audio', recordingBlob);
    data.append('referencecalibration', referenceCalibration);
    var browserInfo=tools.getBrowserInfo();
    data.append('recorderdeviceinfo', browserInfo);
    data.append('micinfo',JSON.stringify(micInfo));
    return restapi.post("/uploadmiccalibrationrecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }
  async getMicCalibrationRecording(type) {
    const data = this.getDataForm();
    data.append('type', type);
    return restapi.post("/getmiccalibrationrecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setSkipText(textKey) {
    const data = this.getDataForm();
    data.append('textkey',textKey);
    return restapi.post("/setskiptext", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setSkipRows(textSetKey,fromRow,toRow) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    data.append('fromrow',fromRow);
    data.append('torow',toRow);
    return restapi.post("/setskiprows", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteAllSkippedTexts() {
    const data = this.getDataForm();
    return restapi.post("/deleteallskippedtexts", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getAllRecordingsMetaData() {
    const data = this.getDataForm();
    return restapi.post("/getallrecordingsmetadata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getUnsynchedRecordings(getRecordingsForAllUsers) {
    const data = this.getDataForm();
    data.append('getrecordingsforallusers',getRecordingsForAllUsers);
    return restapi.post("/getunsynchedrecordings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getRecordingDataSync(firstRecordingDataKey, secondRecordingDataKey) {
    const data = this.getDataForm();
    data.append('firstrecordingdatakey', firstRecordingDataKey);
    data.append('secondrecordingdatakey', secondRecordingDataKey);
    return restapi.post("/getrecordingdatasync", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setRecordingDataSync(firstRecordingDataKey, secondRecordingDataKey, secondRecordingSyncDelaySeconds, syncInfo) {
    const data = this.getDataForm();
    data.append('firstrecordingdatakey', firstRecordingDataKey);
    data.append('secondrecordingdatakey', secondRecordingDataKey);
    data.append('secondrecordingsyncdelayseconds', secondRecordingSyncDelaySeconds);
    data.append('syncinfo',JSON.stringify(syncInfo));
    return restapi.post("/setrecordingdatasync", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }






/*User services*/
  async getUserEmailVerified() {
    const data = this.getDataForm();
    return restapi.post("/getuseremailverified", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async syncUserDataFromAuth0ToDB() {
    const data = this.getDataForm();
    return restapi.post("/syncuserdatafromauth0todb", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getUserData(selectAuthenticatedUser=false) {
    const data = this.getDataForm();
    data.append('selectauthenticateduser',selectAuthenticatedUser);
    return restapi.post("/getuserdata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setUserData(userData) {
    const data = this.getDataForm();
    data.append('userdata',JSON.stringify(userData));
    return restapi.post("/setuserdata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getUserPermissions() {
    const data = this.getDataForm();
    return restapi.post("/getuserpermissions", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getUserLanguages() {
    const data = this.getDataForm();
    return restapi.post("/getuserlanguages", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setUserLanguage(userLanguage) {
    const data = this.getDataForm();
    data.append('userlanguage',JSON.stringify(userLanguage));
    return restapi.post("/setuserlanguage", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async addUserLanguage(userLanguage) {
    const data = this.getDataForm();
    data.append('userlanguage',JSON.stringify(userLanguage));
    return restapi.post("/adduserlanguage", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteOrArchiveUserLanguage(userLanguageKey) {
    const data = this.getDataForm();
    data.append('userlanguagekey',userLanguageKey);
    return restapi.post("/deleteorarchiveuserlanguage", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setUserEmail(email) {
    const data = this.getDataForm();
    data.append('email',email);
    data.append('clientid',process.env.VUE_APP_AUTH0_CLIENTID); //This is needed for the Auth0 to know which client the callback link URL in the email template shall point to
    return restapi.post("/setuseremail", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async sendEmailVerification() {
    const data = this.getDataForm();
    data.append('clientid',process.env.VUE_APP_AUTH0_CLIENTID); //This is needed for the Auth0 to know which client the callback link URL in the email template shall point to
    return restapi.post("/sendemailverification", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async sendChangePasswordEmail() {
    const data = this.getDataForm();
    data.append('clientid',process.env.VUE_APP_AUTH0_CLIENTID); //This is needed for the Auth0 to know which client the callback link URL in the email template shall point to
    return restapi.post("/sendchangepasswordemail", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async checkUserNameAvailable(userName) {
    const data = this.getDataForm();
    data.append('username',userName);
    return restapi.post("/checkUserNameAvailable", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async uploadUserPhotoFile(fileInput) {
    const data = this.getDataForm();
    data.append('file',fileInput);
    return restapi.post("/uploaduserphotofile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }
  async uploadMicLevelSettingsPhotoFile(fileInput) {
    const data = this.getDataForm();
    data.append('file',fileInput);
    return restapi.post("/uploadmiclevelsettingsphotofile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }
  async getAllUsers() {
    const data = this.getDataForm();
    return restapi.post("/getallusers", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }
  async getUserAuthenticationInfo(userKey) {
    const data = this.getDataForm();
    data.append('userkey',userKey);
    return restapi.post("/getuserauthenticationinfo", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }

  async getProjects() {
    const data = this.getDataForm();
    return restapi.post("/getprojects", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }
                          //prepared for TypeScript migration. 
  async createProject(isUser/*: Boolean*/, projectName/*: string*/, description/*: string*/, platform /*: string*/, editingProjectKey=null/*: Number*/) {
    const data = this.getDataForm();
    data.append('isuser',isUser);
    data.append('projectname',projectName);
    data.append('description',description);
    data.append('platform',platform);
    data.append('editingprojectkey',editingProjectKey);
    return restapi.post("/createproject", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }
                            //prepared for TypeScript migration.
  async deleteProject(projectKey/*: Number*/) {
    const data = this.getDataForm();
    data.append('projectkey',projectKey);
    return restapi.post("/deleteproject", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }

  async setProjectmemberRoles(projectKey/*: Number*/, roles/*: Object */) {
    const data = this.getDataForm();
    data.append('projectkey',projectKey);
    data.append('roles',JSON.stringify(roles));
    return restapi.post("/setprojectmemberroles", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }


/*Text Content services*/
  async getUploadedContent() {
    const data = this.getDataForm();
    return restapi.post("/getuploadedcontent", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async uploadContentFile(fileInput) {
    const data = this.getDataForm();
    data.append('file',fileInput);
    return restapi.post("/uploadcontentfile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }


  async getUploadedContentFile(uploadedContentKey) {
    const data = this.getDataForm();
    data.append('uploadedcontentkey',uploadedContentKey);
    return restapi.post("/getuploadedcontentfile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getUploadedContentFileDataAsJSON(uploadedContentKey) {
    const data = this.getDataForm();
    data.append('uploadedcontentkey',uploadedContentKey);
    return restapi.post("/getuploadedcontentfiledataasjson", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async updateUploadedContentFile(uploadedContentKey,updatedForm) {
    const data = this.getDataForm();
    data.append('uploadedcontentkey',uploadedContentKey);
    data.append('form',JSON.stringify(updatedForm));
    return restapi.post("/updateuploadedcontentfile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteUploadedContentFile(uploadedContentKey) {
    const data = this.getDataForm();
    data.append('uploadedcontentkey',uploadedContentKey);
    return restapi.post("/deleteuploadedcontentfile", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getTextFilters(iso639_2) {
    const data = this.getDataForm();
    data.append('iso639_2',iso639_2);
    return restapi.post("/gettextfilters", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getAllTextFiltersForSynthPreprocess() {
    const data = this.getDataForm();
    return restapi.post("/getalltextfiltersforsynthpreprocess", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async createTextSet(textSet,name,description,iso639_2,source,createInfo,image,textsourcetype) {
    const data = this.getDataForm();
    const blob = new Blob([JSON.stringify(textSet)], {type : 'application/json'});
    data.append('textset',blob);
    data.append('name',name);
    data.append('description',description);
    data.append('iso639_2',iso639_2);
    data.append('source',source);
    data.append('textsourcetype',textsourcetype);
    data.append('createinfo',JSON.stringify(createInfo));
    if (image!=null) {
      data.append('image',image);
    }
    return restapi.post("/createtextset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    });
  }
  
  async getAvailableTextSets(showAllLanguages) {
    const data = this.getDataForm();
    data.append('showalllanguages',showAllLanguages);
    return restapi.post("/getavailabletextsets", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getAvailableTextSetsForRecording() {
    const data = this.getDataForm();
    return restapi.post("/getavailabletextsetsforrecording", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteOrArchiveTextSet(textSetKey,doDelete) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    data.append('dodelete',doDelete);
    return restapi.post("/deleteorarchivetextset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getTextSet(textSetKey) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    return restapi.post("/gettextset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getTextSetImage(textSetKey) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    return restapi.post("/gettextsetimage", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getTextsForTextSet(textSetKey) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    return restapi.post("/gettextsfortextset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setIsPublicForTextSet(textSetKey,isPublic) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    data.append('ispublic',isPublic);
    return restapi.post("/setispublicfortextset", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getTextSetShares(textSetKey) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    return restapi.post("/gettextsetshares", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setTextSetShare(textSetShareKey,textSetShare) {
    const data = this.getDataForm();
    data.append('textsetsharekey',textSetShareKey);
    data.append('textsetshare',JSON.stringify(textSetShare));
    return restapi.post("/settextsetshare", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async addTextSetShare(textSetKey,textSetShare) {
    const data = this.getDataForm();
    data.append('textsetkey',textSetKey);
    data.append('textsetshare',JSON.stringify(textSetShare));
    return restapi.post("/addtextsetshare", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteTextSetShare(textSetShareKey) {
    const data = this.getDataForm();
    data.append('textsetsharekey',textSetShareKey);
    return restapi.post("/deletetextsetshare", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }



/*Terms of Service*/
  async getTermsOfServiceForUser() {
    const data = this.getDataForm();
    return restapi.post("/gettermsofserviceforuser", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setTermsOfServiceAcceptedForUser(version) {
    const data = this.getDataForm();
    data.append('version',version);
    return restapi.post("/settermsofserviceacceptedforuser", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getLatestTermsOfService() {
    const data = this.getDataForm();
    return restapi.post("/getlatesttermsofservice", data, {
      headers: {
      }
    });
  }



/*Admin functionality*/
  async getAdminLatestRecordings(historyInDays) {
    const data = this.getDataForm();
    data.append('historyindays',historyInDays);
    return restapi.post("/getadminlatestrecordings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getAdminRecordingSets() {
    const data = this.getDataForm();
    return restapi.post("/getadminrecordingsets", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }



/*Model Trainings*/
  async getModelTrainings() {
    const data = this.getDataForm();
    return restapi.post("/getmodeltrainings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getModelTrainingMetaData(modelTrainingKey) {
    const data = this.getDataForm();
    data.append('modeltrainingkey',modelTrainingKey);
    return restapi.post("/getmodeltrainingmetadata", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async synthTextToSpeech(modelTrainingKey, emotionText, synthOptions) {
    const data = this.getDataForm();
    data.append('modeltrainingkey',modelTrainingKey);
    data.append('emotiontext',JSON.stringify(emotionText));
    data.append('synthoptions',JSON.stringify(synthOptions));
    return restapi.post("/synthtexttospeech", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async waitForSynthResult(synthTextKey) {
    const data = this.getDataForm();
    data.append('synthtextkey',synthTextKey);
    return restapi.post("/waitforsynthresult", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteModelTraining(modelTrainingKey,hardDelete) {
    const data = this.getDataForm();
    data.append('modeltrainingkey',modelTrainingKey);
    data.append('harddelete',hardDelete);
    return restapi.post("/deletemodeltraining", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async requestSynthServerStart(forcedStart,onPageLoad) {
    const data = this.getDataForm();
    data.append('forcedstart',forcedStart);
    data.append('onpageload',onPageLoad);
    return restapi.post("/requestSynthServerStart", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  /*Products*/
  async getAllProducts() {
    const data = this.getDataForm();
    return restapi.post("/getallproducts", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }     
                              //To prepare for TypeScript migration.
  async getProduct(productId/*: string*/) {
    const data = this.getDataForm();
    data.append('productid',productId);
    return restapi.post("/getproduct", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

                            //To prepare for TypeScript migration.
  async getProductOrders(projectKey/*: Number*/) {
    const data = this.getDataForm();
    data.append('projectkey',projectKey);
    return restapi.post("/getproductorders", data, {
      headers: {  
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

                                    //To prepare for TypeScript migration.
  async placeProductOrder(productKey/*: Number*/, projectKey/*: Number*/) {
    const data = this.getDataForm();
    data.append('productkey',productKey);
    data.append('projectkey',projectKey);
    return restapi.post("/placeproductorder", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async productInfoJsonDownload(productKey/*: Number*/, projectKey/*: Number*/) {
    const data = this.getDataForm();
    data.append('productkey',productKey);
    data.append('projectkey',projectKey);
    return restapi.post("/productinfojsondownload", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
                                    //To prepare for TypeScript migration.
  async productDownload(productKey/*: Number*/, projectKey/*: Number*/) {
    const data = this.getDataForm();
    data.append('productkey',productKey);
    data.append('projectkey',projectKey);
    return restapi.post("/productdownload", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getActorAssets(productKeys/*: Object*/) {
    const data = this.getDataForm();
    data.append('productkeys',JSON.stringify(productKeys));
    return restapi.post("/getactorassets", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }


  async getPricingView() {
    const data = this.getDataForm();
    return restapi.post("/getpricingview", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`,
      }
    });
  }



/*System Control*/
  //Empty settingsId means all settingsIds
  async getSystemSettings(settingsId) {
    const data = this.getDataForm();
    data.append('settingsid',settingsId);
    return restapi.post("/getsystemsettings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setSystemSettings(settings) {
    const data = this.getDataForm();
    data.append('settings',JSON.stringify(settings));
    return restapi.post("/setsystemsettings", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async getSynthServers() {
    const data = this.getDataForm();
    return restapi.post("/getsynthservers", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async addSynthServer(instanceName) {
    const data = this.getDataForm();
    data.append('instancename',instanceName);
    return restapi.post("/addsynthserver", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async deleteSynthServer(instanceName) {
    const data = this.getDataForm();
    data.append('instancename',instanceName);
    return restapi.post("/deletesynthserver", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }
  async setSynthServerActive(instanceName, active) {
    const data = this.getDataForm();
    data.append('instancename',instanceName);
    data.append('active',active);
    return restapi.post("/setsynthserveractive", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

  async getUserRecordingDevices() {
    const data = this.getDataForm();
    return mobileApi.post("/get-recording-devices", data, {
      headers: {
        'Authorization': `Bearer ${await this.getAccessToken()}`
      }
    });
  }

}



import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from "../auth/authGuard";



//Login and User registration
const LandingPageView  = () => import('../views/LandingPageView.vue')
const LoggedinView  = () => import('../views/LoggedinView.vue')
// Container
const TheContainer = () => import('../containers/TheContainer.vue')
const DeveloperContainer = () => import('../containers/DeveloperContainer.vue')
const NoPermissionView = () => import('../views/NoPermissionView.vue')
const RecordingsView = () => import('../views/RecordingsView.vue')
const DashboardView = () => import('../views/DashboardView.vue')
const RecordingSetView = () => import('../views/RecordingSetView.vue')
const RecordingTextView = () => import('../views/RecordingTextView.vue')
const TextManagerView = () => import('../views/TextManagerView.vue')
const UploadedContentFileView = () => import('../views/UploadedContentFileView.vue')
const ProcessContentFileView = () => import('../views/ProcessContentFileView.vue')
const TextSetView = () => import('../views/TextSetView.vue')
const NewRecordingView = () => import('../views/NewRecordingView.vue')
const UserProfileView = () => import('../views/UserProfileView.vue')
const ChangeEmailAddressView = () => import('../views/ChangeEmailAddressView.vue')
const UserLanguagesView = () => import('../views/UserLanguagesView.vue')
const SettingsView = () => import('../views/SettingsView.vue')
// const TextToSpeechView = () => import('../views/TextToSpeechView.vue')
const TermsOfServiceView = () => import('../views/TermsOfServiceView.vue')
const ShareTextSetView = () => import('../views/ShareTextSetView.vue')
const AdminLatestRecordingsView = () => import('../views/AdminLatestRecordingsView.vue')
const MicControlsView = () => import('../views/MicControlsView.vue')
const AdminRecordingSetsView = () => import('../views/AdminRecordingSetsView.vue')
const AdminSystemSettingsView = () => import('../views/AdminSystemSettingsView.vue')
const AdminUserManagementView = () => import('../views/AdminUserManagementView.vue')
const BalanceRecordingsView = () => import('../views/BalanceRecordingsView.vue')
const CameraControlsView = () => import('../views/CameraControlsView.vue')
const AnalyzeRecordingView = () => import('../views/AnalyzeRecordingView.vue')
const SyncAudioVideoView = () => import('../views/SyncAudioVideoView.vue')
const ActorListView = () => import('../views/ActorListView.vue')
const ActorPageView = () => import('../views/ActorPageView.vue')
const ActorEthicsPageView = () => import('../views/ActorEthicsPageView.vue')
const MyProjectsView = () => import('../views/MyProjectsView.vue')
const PricingView = () => import('../views/PricingView.vue')
const DevUserProfileView = () => import('../views/DevUserProfileView.vue')




Vue.use(Router)

//export default new Router({
let theRouter=new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landingpage',
      component: LandingPageView,
    },
    {
      path: '/termsofservice',
      name: 'termsofservice',
      component: TermsOfServiceView,
    },
    {
      path: '/login',
      component: TheContainer,        //if navigation as a dev account to this url then nothing happens and we get stuck here without getting rerouted
      name: 'login',
      props: {showHeader:false},
      beforeEnter: authGuard,
      children: [
        {
          path: '/ui/loggedin',
          name: 'loggedin',
          component: LoggedinView,
          beforeEnter: authGuard,
        },
      ],  
    },
    {
      path: '/ui',
      name: 'actorui',
      component: TheContainer,
      props: {showHeader:true},
      beforeEnter: authGuard,
      children: [
        {
          path: '/ui/nopermission/:permissionId',
          name: 'nopermission',
          component: NoPermissionView, 
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/dashboard',    //navigation happens but there is nothing there. Should be rerouted to devui/dashboard that is the user type
          name: 'dashboard',
          component: DashboardView, 
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordings',
          name: 'recordings',
          component: RecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/newrecording',
          name: 'newrecording',
          component: NewRecordingView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingset/:recordingSetKey',
          name: 'recordingset',
          component: RecordingSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingtext/:recordingSetKey/:inputTextKey',
          name: 'recordingtext',
          component: RecordingTextView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingtext/:recordingSetKey',
          name: 'recordingtextstart',
          component: RecordingTextView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/textmanager',
          name: 'textmanager',
          component: TextManagerView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/uploadedcontentfile/:uploadedContentKey',
          name: 'uploadedcontentfile',
          component: UploadedContentFileView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/processcontentfile/:uploadedContentKey',
          name: 'processcontentfile',
          component: ProcessContentFileView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/textset/:textSetKey',
          name: 'textset',
          component: TextSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/userprofile',
          name: 'userprofile',
          component: UserProfileView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/changeemailaddress',
          name: 'changeemailaddress',
          component: ChangeEmailAddressView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/userlanguages',
          name: 'userlanguages',
          component: UserLanguagesView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/settings',
          name: 'settings',
          component: SettingsView,
          beforeEnter: authGuard,
        },
        // {
        //   path: '/ui/texttospeech',
        //   name: 'texttospeech',
        //   component: TextToSpeechView,
        //   beforeEnter: authGuard,
        // },
        {
          path: '/ui/sharetextset/:textSetKey',
          name: 'sharetextset',
          component: ShareTextSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminrecordingsets',
          name: 'adminrecordingsets',
          component: AdminRecordingSetsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminlatestrecordings',
          name: 'adminlatestrecordings',
          component: AdminLatestRecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/miccontrols',
          name: 'miccontrols',
          component: MicControlsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminsystemsettings',
          name: 'adminsystemsettings',
          component: AdminSystemSettingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminusermanagement',
          name: 'adminusermanagement',
          component: AdminUserManagementView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/balancerecordings',
          name: 'balancerecordings',
          component: BalanceRecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/cameracontrols',
          name: 'cameracontrols',
          component: CameraControlsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/analyzerecording/:recordingKey',
          name: 'analyzerecording',
          component: AnalyzeRecordingView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/syncaudiovideo/:loadRecordingKey?/:loadMicrophoneDataKey?/:loadCameraDataKey?',
          name: 'syncaudiovideo',
          component: SyncAudioVideoView,
          props: true,
          beforeEnter: authGuard,
        },
      ]
    },
    {
      path: '/devui',
      name: 'devui',
      component: DeveloperContainer,
      props: {showHeader:true},
      beforeEnter: authGuard,
      children: [
        {
          path: '/devui/nopermission/:permissionId',     
          name: 'devnopermission',
          component: NoPermissionView, 
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/myprojects',
          name: 'myprojects',
          component: MyProjectsView,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/pricing',
          name: 'pricing',
          component: PricingView,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/actorlist', 
          name: 'actorlist',
          component: ActorListView,
          beforeEnter: authGuard,
        }, 
        {
          path: '/devui/actorpage/:actorid',
          name: 'actorpage',
          component: ActorPageView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/actorethicspage',
          name: 'actorethicspage',
          component: ActorEthicsPageView,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/userprofile',
          name: 'devuserprofile',
          component: DevUserProfileView,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/changeemailaddress',
          name: 'devchangeemailaddress',
          component: ChangeEmailAddressView,
          beforeEnter: authGuard,
        },
        // {
        //   path: '/devui/texttospeech',
        //   name: 'devtexttospeech',
        //   component: TextToSpeechView,
        //   beforeEnter: authGuard,
        // },
        {
          path: '/devui/adminsystemsettings',
          name: 'devadminsystemsettings',
          component: AdminSystemSettingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/devui/devadminusermanagement',
          name: 'devadminusermanagement',
          component: AdminUserManagementView,
          beforeEnter: authGuard,
        },
      ]
    }
  ]
})


export default theRouter;


import { getInstance } from "./index";
import store from '../store'

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, check that the user has permission for the subtree it is in, if so continue with the route otherwise reroute to the other trees dashboard
    if (authService.isAuthenticated) {
      if (to.path.startsWith("/ui") && !store.getters.hasPermission('RECORD') && !to.path.startsWith("/ui/loggedin")) {
        return next("/devui/myprojects"); // Redirect developers to their projects
      } else if (to.path.startsWith("/devui") && !store.getters.hasPermission('DEVELOPER')) {   //unclear if this is the correct permission. Companies might want roles for users that are not developers (say finance people for billing)
        return next("/ui/dashboard"); // Redirect actors to their dashboard
      } else {  //as of now 
        return next(); // Allow navigation
      }
      // return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
import { Tooltip } from '@coreui/coreui';

const TooltipDirective = {
  install(Vue) {
    Vue.directive('tooltip', {
      bind(el, binding) {
        el.setAttribute('title', binding.value); // Set tooltip text
        el.setAttribute('data-coreui-toggle', 'tooltip'); // Enable tooltip
        el.setAttribute('data-coreui-placement', 'top'); // Default placement

        // Initialize the CoreUI tooltip instance
        el._tooltipInstance = new Tooltip(el);
      },
      update(el, binding) {
        if (binding.value !== binding.oldValue) {
          el.setAttribute('title', binding.value); // Update tooltip text
          if (el._tooltipInstance) {
            try {
              el._tooltipInstance.dispose(); // Dispose previous instance safely
            } catch (error) {
              console.warn("Tooltip disposal error (update):", error);
            }
          }
          Vue.nextTick(() => {
            if (document.body.contains(el)) {
              el._tooltipInstance = new Tooltip(el);
            }
          });      
        }
      },
      unbind(el) {
        // Ensure the tooltip exists before trying to dispose
        if (el._tooltipInstance) {
          try {
            el._tooltipInstance.dispose(); // Dispose tooltip safely
          } catch (error) {
            console.warn("Tooltip disposal error (unbind):", error);
          }
          el._tooltipInstance = null; // Nullify the instance reference
        }
      }
    });
  }
};

export default TooltipDirective;
